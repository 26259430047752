<template>
  <v-layout class="d-flex">
    <v-card height="133" width="340">
      <v-sheet
        :color="'rgba(102, 45, 145, 0.1)'"
        class="ma-4 cardsIconContainer"
      >
        <v-img
          class="mt-2"
          max-height="16"
          max-width="16"
          :lazy-src="calendar"
          :src="calendar"
        ></v-img>
      </v-sheet>
      <v-card-text class="pr-4 ma-0 pa-0">
        <div style="color: black">
          <span class="font-14 upperText">الفتره التجريبيه </span>
          <div class="font-12">
            <b class="font-16">{{ 60 - daysLeftCalc }} يوم </b>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="d-flex justify-space-around w-100 align-center mr-4">
      <ul>
        <li class="d-flex my-2">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span class="font-16"> إضافه {{ feautres.realestates }} عقار </span>
        </li>
        <li class="d-flex my-2">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span class="font-16"> إضافه {{ feautres.clients }} عميل </span>
        </li>
        <li class="d-flex my-2">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span class="font-16">
            مرفقات حتي {{ feautres.attachements || feautres.attachments }} ميجا
            بايت
          </span>
        </li>
      </ul>
      <ul>
        <li class="d-flex my-2">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span class="font-16">
            إضافه {{ feautres.realestatesPerDay }} عقارات يومياً
          </span>
        </li>
        <li class="d-flex my-2" v-if="feautres.team">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span class="font-16">
            إضافه {{ feautres.team }} عضو لفريق التشغيل
          </span>
        </li>
        <li class="d-flex my-2">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span v-if="feautres.contracts == 'unlimited'" class="font-16">
            عدد مفتوح من العقود
          </span>
          <span v-else class="font-16">
            إضافه {{ feautres.contracts }} من العقود
          </span>
        </li>
        <li class="d-flex my-2">
          <span class="mt-3 mr-7 ml-3">
            <v-img max-width="11" :src="rightCheckMark"></v-img>
          </span>
          <span class="font-16"> إضافه {{ feautres.clients }} عميل </span>
        </li>
      </ul>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  name: 'trialFree',
  props: {
    bundle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      calendar: require('@/assets/img/svgs/calendar.svg'),
      rightCheckMark: require('@/assets/img/svgs/rightCheckMark.svg'),
      feautres: {}
    }
  },
  watch: {
    bundle(newVal) {
      this.feautres = newVal.features
    }
  },
  computed: {
    daysLeftCalc() {
      const daysLeft = new Date(
        JSON.parse(localStorage.getItem('tokenData')).createdAt
      )
      return (
        Math.round(
          (new Date().getTime() - daysLeft.getTime()) / (1000 * 3600 * 24)
        ) + 1
      )
    }
  },
  created() {
    this.feautres = this.bundle?.features
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/material-dashboard/_statistics-cards.scss';
</style>
