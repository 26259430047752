<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.title"
          :eager="true"
        >
          <v-card class="px-5 py-6 mt-4" v-if="item.hasPermission">
            <component
              :is="item.name"
              :ref="item.name"
              @openApprovalModal="toggleApprovalModel"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <ApprovalModal
      :dialogState="approvalDialogState"
      :allEmployees="allEmployees"
      :module="module"
      :hasUpdatePermission="hasUpdatePermission('approvalsSettings')"
      :jobTitles="jobTitles"
      @setJobTitles="setJobId"
      @closeDialog="toggleApprovalModel"
      @loadMoreEmployees="getEmployeesNextData"
    />
  </div>
</template>

<script>
import { EnvService, userService } from '@/services'
import { buildQueryString } from '@/utils/general'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ApprovalTabs',
  components: {
    Approvement: () =>
      import('@/components/profile/env-setting-comps/Approvement.vue'),
    ApprovalModal: () =>
      import('@/components/profile/env-setting-comps/ApprovalModal.vue')
  },
  data() {
    return {
      tab: 0,
      approvalDialogState: false,
      allEmployees: {},
      jobTitles: [],
      module: {},
      level: null,
      paginationEmployees: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    availableTabs() {
      return [
        {
          title: 'الموافقات العامة',
          name: 'Approvement',
          hasPermission:
            !!this.isOwner || this.hasListPermission('approvalsSettings')
        }
        // { title: 'الحالات الخاصة', name: '', hasPermission: true }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setJobId(id, level) {
      this.level = level
      this.jobId = id
      this.loadAllEmployees()
    },
    async getEmployeesNextData() {
      if (
        this.paginationEmployees.pageNumber <
        this.paginationEmployees.pagesCount
      ) {
        this.paginationEmployees.pageNumber += 1
        await this.loadAllEmployees()
      }
    },
    hasListPermission(module) {
      return !!this.$hasPermission('users', module, 'list')
    },
    hasUpdatePermission(module) {
      return !!this.$hasPermission('users', module, 'update')
    },
    toggleApprovalModel(state) {
      this.approvalDialogState = state.dialogState
      if (state.approval) {
        this.module = state.approval
      }
    },
    updateEmployees(data) {
      this.$set(this.allEmployees, `level${this.level}`, data?.users)
    },
    async loadAllEmployees() {
      try {
        const param = buildQueryString(
          {
            pageSize: this.paginationEmployees.pageSize,
            pageNumber: this.paginationEmployees.pageNumber,
            registered: 1,
            ...(this.jobId && { jobId: this.jobId })
          },
          '&'
        )
        const { data } = await EnvService.getAllEmployee(
          this.currentEnv._id,
          param
        )
        this.calculatePagination(data)
        this.updateEmployees(data)
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل المسئولين',
          color: 'error',
          duration: 4000
        })
      }
    },
    async getAllJobsTitles() {
      const query = buildQueryString({
        environmentId: this.currentEnv?._id
      })
      try {
        const { data } = await EnvService.getJobTitles(query)
        this.jobTitles = data.envJobTitles
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل الموظفين',
          color: 'error',
          duration: 4000
        })
      }
    },
    calculatePagination(data) {
      this.paginationEmployees.pagesCount = Math.ceil(
        data?.count / this.paginationEmployees.pageSize
      )
      this.paginationEmployees.count = data?.count ?? 0
    }
  },
  async created() {
    await this.getAllJobsTitles()
  }
}
</script>
