<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.title"
          :eager="true"
        >
          <!-- <v-card class="px-5 py-6 mt-4" v-if="item.hasPermission"> -->
          <component
            :is="item.name"
            :env="currentEnv"
            :userData="user"
            :isBankAccountSaveBtn="true"
            :addBanksDataLoading="addBanksDataLoading"
            :bankAccountsDetails="bankAccountsDetails"
            @bindBankAccountsData="bindBankAccountsData"
            @updateBankAccountsList="getEnvBankAccounts"
            @updateFinancialData="updateFinancialData"
          />
          <!-- </v-card> -->
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { EnvService, userService } from '@/services'

export default {
  name: 'FinantionalSettings',
  components: {
    bankAccounts: () => import('@/components/details/bankAccounts.vue'),
    FinancialSettingsssss: () =>
      import('@/components/profile/env-setting-comps/FinancialSettings.vue')
  },
  data: () => ({
    env: {},
    addBanksDataLoading: false,
    bankAccountsDetails: [],
    userData: {},
    tab: 0
  }),
  watch: {
    currentEnv() {
      this.env = { ...this.currentEnv }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    availableTabs() {
      return [
        {
          title: 'إعدادات مالية',
          name: 'FinancialSettingsssss',
          hasPermission: !!this.isOwner || this.hasListPermission('financial')
        },
        {
          title: 'الحسابات البنكية',
          name: 'bankAccounts',
          hasPermission: !!this.isOwner || this.hasListPermission('financial')
        }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['updateUserProfile', 'updateEnvSettings']),
    async updateFinancialData({ env, userData }) {
      try {
        await userService.updateEnv(env)
        await this.updateUserProfile(userData)
        localStorage.setItem('currentEnv', JSON.stringify(env))
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحديث البيانات المالية, من فضلك حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    hasListPermission(module) {
      return !!this.$hasPermission('users', module, 'list')
    },
    async bindBankAccountsData(bankAccountsData) {
      try {
        this.addBanksDataLoading = true
        const payload = bankAccountsData.map((account) => {
          return {
            ...account,
            entityId: this.currentEnv?._id,
            entityType: 'Environment',
            environmentId: this.currentEnv?._id
          }
        })
        await EnvService.addEnvBankAccount({ bankAccounts: payload })
        this.getEnvBankAccounts()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من إضافة حسابك البنكي, من فضلك حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.addBanksDataLoading = false
      }
    },
    async getEnvBankAccounts() {
      try {
        const { data } = await EnvService.getEnvOrClientBankAccounts(
          this.currentEnv?._id
        )
        this.bankAccountsDetails = data.bankAccounts
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل بيانات الحساب',
          color: 'error'
        })
      }
    }
  },
  async created() {
    await this.getEnvBankAccounts()
  }
}
</script>
