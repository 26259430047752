<template>
  <div
    id="change-password"
    class="change-password page-main-bg d-flex flex-column"
  >
    <v-container>
      <!--tool-bar-->
      <tool-bar :breadcrumbs="breadcrumbs" :title="environmentTitle" />
      <!--tabs-->

      <v-tabs
        align-with-title
        color="primary"
        right
        show-arrows
        v-model="tab"
        class="setting__page__tabs"
        @change="editRouteParam"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="(item, i) in availableTabsItem"
          :key="i"
        >
          {{ item.componentTitle }}
        </v-tab>

        <v-tabs-items class="mt-5 profile-tabs" v-model="tab">
          <v-tab-item
            v-for="(item, i) in availableTabsItem"
            :key="i"
            :eager="true"
          >
            <component
              :is="item.componentNameEn"
              :bills="bills"
              :bundles="bundles"
              :pagination="billsPagination"
              @routeToProfile="routeToProfile"
              @routeToSubscription="routeToSubscription"
              @handleFilters="handleBillsFilters"
              @getBills="getBills"
              @setBundle="setSelectedBundle"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <add-bundle
        ref="addBundle"
        :bundle="bundle"
        :renewedSubscription="renewedSubscription"
        :dialogState="dialogState"
        @applyCopoun="setRenewedSubscription($event)"
        @routeToBills="routeToBills"
        @changeDialogState="dialogState = $event"
        @close-add-dialog="dialogState = false"
      />
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { userService, subscriptionService } from '@/services'
import AddBundle from '@/components/profile/add-bundle'
import ToolBar from '@/components/listing/ToolBar'
// import models from '@/components/profile/models'
import EnvSittings from '@/components/profile/env-sittings'
import NotificationsModels from '@/components/profile/NotificationsModels.vue'
import Bills from './Bills'
import trialfree from './trialfree'
import Profile from '@/components/profile/Profile.vue'
import InvitationSettings from '@/components/profile/InvitationSettings.vue'
import SubscriptionSettings from '@/components/profile/SubscriptionSettings'
import TeamSettings from '@/components/profile/TeamSettings.vue'
import FinantionalSettings from './FinantionalSettings.vue'
import ApprovalTabs from '@/views/ApprovalTabs.vue'

export default {
  name: 'EditProfile',
  components: {
    Bills,
    AddBundle,
    EnvSittings,
    ToolBar,
    ApprovalTabs,
    trialfree,
    TeamSettings,
    // models,
    branding: () => import('@/components/profile/branding'),
    contractSetting: () =>
      import('@/components/profile/env-setting-comps/contractSetting.vue'),
    Services: () => import('@/views/ServiceTab.vue'),
    FinantionalSettings,
    NotificationsModels,
    Profile,
    InvitationSettings,
    SubscriptionSettings
  },
  data() {
    return {
      filters: {},
      billsPagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      dialogState: false,
      editProfileComponents: [
        {
          componentTitle: 'إعدادات الحساب',
          componentNameEn: 'profile',
          isCustomer: true
        },
        {
          componentTitle: 'بوابة العملاء',
          componentNameEn: 'InvitationSettings',
          isCustomer: false
        },
        {
          componentTitle: 'الفريق',
          componentNameEn: 'TeamSettings',
          isCustomer: false
        },
        {
          componentTitle: 'العقود',
          componentNameEn: 'contractSetting',
          hasPermission:
            !!this.isOwner || this.hasListPermission('contractsSettings')
        },
        {
          componentTitle: 'المالية',
          componentNameEn: 'FinantionalSettings',
          hasPermission:
            !!this.isOwner || this.hasListPermission('bankAccountsSettings')
        },
        {
          componentTitle: 'الموافقات',
          componentNameEn: 'ApprovalTabs'
        },
        {
          componentTitle: 'الخدمات',
          componentNameEn: 'Services',
          hasPermission:
            !!this.isOwner || this.hasListPermission('servicesSettings'),
          isSaveBtn: false
        },
        {
          componentTitle: 'الإشعارات',
          componentNameEn: 'NotificationsModels',
          isCustomer: false
        },
        // {
        //   componentTitle: 'الفوترة و الإشتراك',
        //   componentNameEn: 'bills',
        //   isCustomer: false
        // },
        {
          componentTitle: 'الفوترة و الإشتراك',
          componentNameEn: 'SubscriptionSettings',
          isCustomer: false
        }
        // {
        //   componentTitle: 'إعدادات الحساب',
        //   componentNameEn: 'env-sittings',
        //   isCustomer: false
        // }
      ],
      tab: 0,
      bundles: [],
      bills: [],
      bundle: {},
      renewedSubscription: {}
    }
  },
  computed: {
    ...mapState('accountState', {
      userBundle: (state) => state.subscription
    }),
    // TODO subscription
    ...mapState('accountState', ['currentEnv', 'user', 'subscription']),
    ...mapGetters('accountState', ['isCustomer']),
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },

    availableTabsItem() {
      if (this.isCustomer) {
        return this.editProfileComponents.filter(({ isCustomer }) => {
          return isCustomer
        })
      } else if (!this.isOwner) {
        return this.editProfileComponents.filter(({ componentNameEn }) => {
          return !this.avaliableTabsForTeamMembers.includes(componentNameEn)
        })
      }
      return this.editProfileComponents
    },
    avaliableTabsForTeamMembers() {
      let excludedTeamMemberTabs = ['bills']

      if (!this.hasInvitationPermission) {
        excludedTeamMemberTabs = [
          ...excludedTeamMemberTabs,
          'InvitationSettings'
        ]
      }
      if (!this.hasNotificationPermission) {
        excludedTeamMemberTabs = [
          ...excludedTeamMemberTabs,
          'NotificationsModels'
        ]
      }
      return excludedTeamMemberTabs
    },
    hasInvitationPermission() {
      return !!(
        (this.subscription.bundle.modules.team ||
          this.subscription.bundle.modules.includes('team')) &&
        !this.isCustomer
      )
    },
    hasNotificationPermission() {
      return !!this.$hasPermission('users', 'notificationsSettings', 'update')
    },
    breadcrumbs() {
      return [
        {
          text: 'الإعدادات',
          disabled: true
        },
        {
          text: this.editProfileComponents[this.tab]?.componentTitle,
          disabled: true
        }
      ]
    },
    environmentTitle() {
      return 'الإعدادات'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapMutations('accountState', ['setUserSubscription']),
    toggleServiceModel(state) {
      this.serviceDialogState = state.dialogState
      this.templateDate = state.template
    },
    hasListPermission(module) {
      return !!this.$hasPermission('users', module, 'list')
    },
    handleErrMessagae(errMessage) {
      if (errMessage.includes('pending')) {
        return 'يتم مراجعه الفاتوره برجاء الإنتظار'
      } else if (errMessage.includes('rejected')) {
        return 'تم رفض الفاتوره, برجاء التواصل مع خدمه العملاء'
      }
      return 'الإشتراك الحالي مدفوع بالفعل'
    },
    setRenewedSubscription(newSubscriptionData) {
      this.renewedSubscription = newSubscriptionData
    },
    async handleBillsFilters(filters = '') {
      this.billsPagination.pageNumber = 1
      let payLoad = `environment=${this.currentEnv._id}&pageSize=${this.billsPagination.pageSize}&pageNumber=${this.billsPagination.pageNumber}`
      if (filters.length) {
        this.filters = filters
        payLoad += `&status=${this.filters}`
      } else {
        this.filters = ''
      }
      const { data } = await subscriptionService.getPayments(payLoad)
      this.bills = data.payments
      this.billsPagination.pagesCount = Math.ceil(
        data.count / this.billsPagination.pageSize
      )
      this.billsPagination.count = data.count
    },
    routeToBills() {
      this.tab = 3
      this.getBills()
    },
    async getBills() {
      try {
        let payLoad = `environment=${this.currentEnv._id}&pageSize=${this.billsPagination.pageSize}&pageNumber=${this.billsPagination.pageNumber}`
        if (this.filters?.length) {
          payLoad += `&status=${this.filters}`
        }
        const { data } = await subscriptionService.getPayments(payLoad)

        this.bills = data.payments

        this.billsPagination.pagesCount = Math.ceil(
          data.count / this.billsPagination.pageSize
        )
        this.billsPagination.count = data.count
      } catch {}
    },
    async getBundles() {
      try {
        const { data } = await userService.getBundles()
        this.bundles = data
      } catch {}
    },
    async updateSubscription(bundle, period) {
      const addNewSubscription = {
        user: this.user._id,
        bundle: bundle._id,
        period
      }
      try {
        const { data } = await subscriptionService.updateSubscription(
          addNewSubscription
        )

        this.bundle = bundle
        // when backend populate bundle this will be removed
        const subscription = { ...data.subscription, bundle }
        this.setUserSubscription(subscription)
        localStorage.setItem('subscription', JSON.stringify(subscription))
        this.addNotification({
          text: 'تم تغيير الباقة بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async popUpConfirm(bundle) {
      return await this.$root.$confirm.open({
        title: 'تغيير الباقة',
        message: `هل انت متأكد من تغيير الباقة الى ${bundle.nameAr}`,
        options: {
          color: 'primary',
          width: 400,
          zIndex: 200
        },
        img: {
          path: require('@/assets/img/svgs/exclamationMark.svg'),
          width: 25,
          height: 56
        }
      })
    },
    async setSelectedBundle({ bundle, period }) {
      if (bundle.nameEn !== this.userBundle.bundle?.nameEn) {
        const confirm = await this.popUpConfirm(bundle)
        if (confirm) {
          this.updateSubscription(bundle, period)
        }
      } else if (
        bundle.nameEn === this.userBundle.bundle?.nameEn &&
        this.userBundle.period !== period
      ) {
        this.addNotification({
          text: 'لا يمكنك تغيير نظام الدفع لباقتك الحاليه',
          color: 'error'
        })
      } else {
        await this.getBundleDetails(bundle)
      }
    },
    async getBundleDetails(bundle) {
      try {
        const reqBody = {
          environment: this.currentEnv._id,
          subscription: this.subscription._id
        }
        const { data } = await subscriptionService.getSubscriptionPayment(
          reqBody
        )
        this.renewedSubscription = data
        this.dialogState = true
        this.bundle = bundle
      } catch (error) {
        this.addNotification({
          text: this.handleErrMessagae(error.response?.data?.error),
          color: 'error'
        })
      }
    },
    getCurrentTab() {
      this.tab = Number(this.$router.currentRoute?.params?.tab ?? 0)
    },
    routeToProfile() {
      this.tab = 0
    },
    routeToSubscription() {
      this.tab = 4
    },
    editRouteParam(val) {
      this.$router.replace(`/dashboard/edit-profile/${val}`)
    }
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.bundle = this.userBundle?.bundle
  },
  mounted() {
    this.getBundles()
    this.getBills()
    this.getCurrentTab()
  },
  metaInfo() {
    return {
      title: 'الملف الشخصي'
    }
  }
}
</script>
